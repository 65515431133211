<template>
  <div :class="`${prefixCls}-way`" id="aochenWay">
    <Separate title="联系方式" desc="R&D OVERVIEW"></Separate>
    <div :class="`${prefixCls}-way-content`">
      <div class="detail-form">
        <div class="form-title">舟山普陀金鳌船艇管理服务有限公司</div>
        <div class="detail-form-item">
          <span class="label">地址：</span>
          <span class="value">舟山市普陀区沈家门街道渔市一路95号海贸大厦1幢901-13室</span>
        </div>
<!--        <div class="form-title">行政办公区</div>-->
<!--        <div class="detail-form-item" v-for="(value, key) in formConfig" :key="key">-->
<!--          <span class="label">{{ value }}：</span>-->
<!--          <span class="value">{{ formData[key] }}</span>-->
<!--        </div>-->
<!--        <div class="form-title">原料药销售</div>-->
<!--        <div class="detail-form-item">-->
<!--          <span class="label">电话：</span>-->
<!--          <span class="value">0580-2085488</span>-->
<!--        </div>-->
<!--        <div class="form-title">销售/售后</div>-->
<!--        <div class="detail-form-item">-->
<!--          <span class="label">电话：</span>-->
<!--          <span class="value">0580-2085301</span>-->
<!--        </div>-->
<!--        <div class="form-title">药物警戒</div>-->
<!--        <div class="detail-form-item">-->
<!--          <span class="label">电话：</span>-->
<!--          <span class="value">0580-2085482</span>-->
<!--        </div>-->
<!--        <div class="bottom-box">-->
<!--          <div class="item-box">-->
<!--            <div class="item-card">-->
<!--              <div class="form-title">行政办公区</div>-->
<!--              <div class="detail-form-item" v-for="(value, key) in formConfig" :key="key">-->
<!--                <span class="label">{{ value }}：</span>-->
<!--                <span class="value">{{ formData[key] }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;          <div class="item-card">&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="form-title">原料药销售</div>&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="detail-form-item">&ndash;&gt;-->
<!--            &lt;!&ndash;              <span class="label">电话：</span>&ndash;&gt;-->
<!--            &lt;!&ndash;              <span class="value">0580-2085488</span>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            &lt;!&ndash;          </div>&ndash;&gt;-->
<!--            <div class="item-card">-->
<!--              <div class="form-title">原料药销售</div>-->
<!--              <div class="detail-form-item">-->
<!--                <span class="label">电话：</span>-->
<!--                <span class="value">0580-2085488</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="item-card">-->
<!--              <div class="form-title">销售/售后</div>-->
<!--              <div class="detail-form-item">-->
<!--                <span class="label">电话：</span>-->
<!--                <span class="value">0580-2085301</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="item-card">-->
<!--              <div class="form-title">药物警戒</div>-->
<!--              <div class="detail-form-item">-->
<!--                <span class="label">电话：</span>-->
<!--                <span class="value">0580-2085482</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div id="map" class="map" />
      <img class="qrcode" src="@/assets/image/qrCode.png" alt="">
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { mapMutations } from 'vuex'
import { removeHTMLTag } from '../../../utils/util'
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  name: 'ContactWay',
  data () {
    return {
      prefixCls: this.$prefix + '-contact',
      centerList: [],
      centerMain: {},
      formConfig: {
        telephone: '电话',
        chuanzhen: '传真',
        email: '邮箱'
      },
      // 行政办公区
      formData: {
        telephone: '0580-2085578',
        chuanzhen: '0580-2085312',
        email: 'hlszyoffice@cy-pharm.com'
      },
      center: [122.284242, 29.940033]
    }
  },
  props: {
    centerData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mounted () {
    this.initMap()
  },
  methods: {
    ...mapMutations(['setData']),
    removeHTMLTag,
    initMap () {
      AMapLoader.load({
        key: 'b5c800a2b46a3ce88f69bc8de3ca3412', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map('map', { // 设置地图容器id
          viewMode: '3D', // 是否为3D地图模式
          zoom: 16, // 初始化地图级别
          center: this.center // 初始化地图中心点位置
        })
        const marker = new AMap.Marker({
          position: new AMap.LngLat(this.center[0], this.center[1]), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: '北京'
        })
        this.map.add(marker)
      }).catch(e => {
        console.log(e)
      })
    },
    goList () {
      const { href } = this.$router.resolve({
        path: '/press/list',
        query: { type: '5' }
      })
      window.open(href, '_blank')
    },
    goDetail (item) {
      // 目标路由对象
      const { href } = this.$router.resolve({
        path: '/press/detail'
      })
      window.open(`${href}?id=${item.id}`, '_blank')
    }
  },
  components: {
    Separate
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -contact-way;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $content-width;
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 25px;
    border: 1px solid #dddddd;
    padding: 20px;
    .qrcode {
      position: absolute;
      top: 5px;
      //left: 350px;
      right: 15px;
      width: 72px;
      height: 72px;
    }
    .form-title {
      font-size: 17px;
      color: rgb(36,134,199);
      margin-bottom: 5px;
      text-align: left;
    }
    .detail-form {
      flex: 1;
      img {
        width: 100px;
        height: 100px;
        float: left;
      }
      &-item {
        display: flex;
        font-size: 14px;
        margin-bottom: 10px;
        .label {
          width: 3em;
          text-align: left;
        }
        .value {
          width: 28em;
          text-align: left;
        }
      }
    }
    .map {
      width: 100%;
      height: 445px;
      border: 1px solid black;
    }
  }
}
</style>
